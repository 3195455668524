import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { Navigate } from "react-router-dom";
import RegisterSign from "../Login/RegisterSign";

import "../../css/navbar.css";
import {
  faArrowDown,
  faBars,
  faCaretDown,
  faCaretUp,
  faSearch,
  faUser,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import checkpointStacked from "../../images/checkpoint_logos/Checkpoint®_stacked_72ppi.png";

function Navbar() {
  const [activeSection, setActiveSection] = useState(null);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showLogo, setShowLogo] = useState(false);
  const [mobileIcon, setMobileIcon] = useState(faBars);
  const [dropdownArrow, setDropdownArrow] = useState(faCaretDown);
  const [showRegisterOrSign, setShowRegisterOrSign] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setShowLogo(true);
      } else {
        setShowLogo(false);
      }
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToSection = (section) => {
    setActiveSection(section);
    const element = document.getElementById(section);
    const offset = element.offsetTop;
    const navbarHeight = showMobileMenu ? 375 : 50;
    window.scrollTo({ top: offset - navbarHeight, behavior: "smooth" });
  };

  const toggleMobileMenu = () => {
    setShowMobileMenu(!showMobileMenu);
    if (!showMobileMenu) {
      setDropdownArrow(faCaretDown);
      setShowDropdown(false);
    }
  };

  const toggleDropdownMenu = () => {
    setShowDropdown(!showDropdown);
  };

  const handleIconClick = () => {
    setMobileIcon(mobileIcon === faBars ? faXmark : faBars);
  };

  const handleDropdownArrowClick = () => {
    setDropdownArrow(dropdownArrow === faCaretDown ? faCaretUp : faCaretDown);
  };

  return (
    <div className="navbar-container">
      <div className={`navbar-menu ${showMobileMenu ? "show" : ""}`}>
        <button
          className="navbar-item"
          id={activeSection === "magazine" ? "active" : ""}
          onClick={() => scrollToSection("magazine")}
        >
          Magazine
        </button>
        <button
          className="navbar-item"
          id={activeSection === "kidsmagazine" ? "active" : ""}
          onClick={() => scrollToSection("kidsmagazine")}
        >
          Kids Magazine
        </button>
        <button
          className="navbar-item"
          id={activeSection === "learning" ? "active" : ""}
          onClick={() => scrollToSection("learning")}
        >
          Learning
        </button>
        <button
          className="navbar-item"
          id={activeSection === "bedtime" ? "active" : ""}
          onClick={() => scrollToSection("bedtime")}
        >
          Bedtime Stories
        </button>
        <button
          className="navbar-item"
          id={activeSection === "multiverse" ? "active" : ""}
          onClick={() => scrollToSection("multiverse")}
        >
          Multiverse
        </button>
        <button
          className="navbar-item"
          id={activeSection === "shop" ? "active" : ""}
          onClick={() => scrollToSection("shop")}
        >
          Shop
        </button>
        <button
          className="navbar-item"
          id={activeSection === "partners" ? "active" : ""}
          onClick={() => scrollToSection("partners")}
        >
          Affiliations
        </button>
        <button className="navbar-item">
          <a href="/kidsmain/submissions">
            Kids Submissions
          </a>
        </button>
        <div
          className={`navbar-dropdown ${showDropdown ? "show" : ""}`}
          onClick={toggleDropdownMenu}
        >
          <button className="navbar-item" onClick={handleDropdownArrowClick}>
            Research
            <FontAwesomeIcon className="dropdown-arrow" icon={dropdownArrow} />
          </button>
          <div className="dropdown-content">
            {showDropdown && (
              <a href="https://checkpointmagazine.com/videogame-sector-esg-report-white-paper/">
                ESG White Paper 2023
              </a>
            )}
          </div>
        </div>
      </div>
      {/* <div className="nav-social">
        <a
          href="https://twitter.com/CheckpointMZine"
          className="nav-social-item"
        >
          <FontAwesomeIcon
            icon={faTwitter}
            style={{ color: "var(--primary)", paddingRight: "5px" }}
          />
        </a>
        <a
          href="https://www.instagram.com/checkpointmzine/"
          className="nav-social-item"
        >
          <FontAwesomeIcon
            icon={faInstagram}
            style={{ color: "var(--primary)", paddingRight: "5px" }}
          />
        </a>
        <a
          href="https://www.facebook.com/checkpointmagazine/"
          className="nav-social-item"
        >
          <FontAwesomeIcon
            icon={faFacebook}
            style={{ color: "var(--primary)", paddingRight: "5px" }}
          />
        </a>
        <a
          href="https://www.linkedin.com/company/checkpointmagazine/?originalSubdomain=uk"
          className="nav-social-item"
        >
          <FontAwesomeIcon
            icon={faLinkedin}
            style={{ color: "var(--primary)", paddingRight: "5px" }}
          />
        </a>
        <a
          href="https://www.youtube.com/channel/UCzlpBWWUpXQJ8nqABMMlDKA"
          className="nav-social-item"
        >
          <FontAwesomeIcon
            icon={faYoutube}
            style={{ color: "var(--primary)", paddingRight: "5px" }}
          />
        </a>
      </div> */}

      {showLogo && (
        <div className="checkpointLogo">
          <img src={checkpointStacked} alt="logo" style={{ height: "60px" }} />
        </div>
      )}
      <div className="icon-menu" onClick={toggleMobileMenu}>
        <FontAwesomeIcon
          className={`dropdown-icon ${showMobileMenu ? "open" : ""}`}
          icon={mobileIcon}
          onClick={handleIconClick}
        />
      </div>

      <div className="mobile-menu">
        {showMobileMenu && (
          <div className="mobile-dropdown-menu">
            <button
              className="navbar-item"
              id={activeSection === "magazine" ? "active" : ""}
              onClick={() => {
                scrollToSection("magazine");
                toggleMobileMenu();
              }}
            >
              Magazine
            </button>
            <button
              className="navbar-item"
              id={activeSection === "kidsmagazine" ? "active" : ""}
              onClick={() => {
                scrollToSection("kidsmagazine");
                toggleMobileMenu();
              }}
            >
              Kids Magazine
            </button>
            <button
              className="navbar-item"
              id={activeSection === "learning" ? "active" : ""}
              onClick={() => {
                scrollToSection("learning");
                toggleMobileMenu();
              }}
            >
              Learning
            </button>
            <button
              className="navbar-item"
              id={activeSection === "bedtime" ? "active" : ""}
              onClick={() => {
                scrollToSection("bedtime");
                toggleMobileMenu();
              }}
            >
              Bedime stories
            </button>
            <button
              className="navbar-item"
              id={activeSection === "multiverse" ? "active" : ""}
              onClick={() => {
                scrollToSection("multiverse");
                toggleMobileMenu();
              }}
            >
              Multiverse
            </button>
            <button
              className="navbar-item"
              id={activeSection === "shop" ? "active" : ""}
              onClick={() => {
                scrollToSection("shop");
                toggleMobileMenu();
              }}
            >
              Shop
            </button>
            <button
              className="navbar-item"
              id={activeSection === "partners" ? "active" : ""}
              onClick={() => {
                scrollToSection("partners");
                toggleMobileMenu();
              }}
            >
              Affiliations
            </button>
            <button
              className="navbar-item"
              href="/kidsmain/submissions"
            >
              Kids Submissions
            </button>
            <div
              className={`navbar-dropdown ${showDropdown ? "show" : ""}`}
              onClick={toggleDropdownMenu}
            >
              <button
                className="navbar-item"
                onClick={handleDropdownArrowClick}
              >
                Research
                <FontAwesomeIcon
                  className="dropdown-arrow"
                  icon={dropdownArrow}
                />
              </button>
              <div className="dropdown-content">
                {showDropdown && (
                  <a href="https://checkpointmagazine.com/videogame-sector-esg-report-white-paper/">
                    ESG White Paper 2023
                  </a>
                )}
              </div>
            </div>
          </div>
        )}
      </div>

      {/* <div className="nav-login">
        <a onClick={() => setShowRegisterOrSign(true)}>
          <FontAwesomeIcon icon={faUser} style={{ color: "var(--primary)" }} />
        </a>
        {showRegisterOrSign && <RegisterSign/>}
        <a>

          <FontAwesomeIcon
            icon={faSearch}
            style={{ color: "var(--primary)", paddingLeft: "5px" }}
          />
        </a>
        <form>
          <input className="search-box" type="text" name="search"></input>
        </form>
      </div> */}
    </div>
  );
}

export default Navbar;
