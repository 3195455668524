import React from "react";

import "../../css/kidsmagazine.css";
import applestore from "../../images/play_store/applestore.png";
import googleplay from "../../images/play_store/googleplay.png";
import pocketmags from "../../images/pocketmags_button.jpeg";
import kidsmagazine from "../../images/ipad/iPad-Pro-Kids_01.png";
import kidsmagazinelogo from "../../images/checkpoint_logos/kidslogo.png";

function KidsMagazine() {
  return (
    <div className="kidsmag-container" id="#kidsmagazine">
      <div>
        <img
          className="kidsmag-img"
          src={kidsmagazine}
          alt="kids magazine cover"
        />
      </div>
      <div className="kidsmag-text">
        <h1>Kids Magazine</h1>
        <p>
          Download the Checkpoint Kids app <br />
          and have access to our FREE magazine
        </p>
        <a href="/kidsmain/kidsmainslide">
          <img
            className="kids-mg-link"
            src={kidsmagazinelogo}
            alt="kids magazine logo"
          />
        </a>
        <div className="kidsmagazine-virtual">
          <a href="https://play.google.com/store/apps/details?id=com.jellyfish.checkpointkids&hl=en_US&gl=US">
            <img
              className="kids-virtualstore-link"
              src={googleplay}
              alt="google play link"
            />
          </a>
          <a href="https://apps.apple.com/gb/app/checkpoint-magazine-kids/id1522005435">
            <img
              className="kids-virtualstore-link"
              src={applestore}
              alt="apple store link"
            />
          </a>
          <a href="https://pocketmags.com/checkpoint-kids-magazine">
            <img
              className="kids-virtualstore-link"
              src={pocketmags}
              alt="pocketmags magazines link"
            />
          </a>
        </div>
      </div>
    </div>
  );
}

export default KidsMagazine;
