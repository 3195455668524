import React from "react";
import "../../css/sponsorsIcon.css";
import "../../css/sponsors.css";

import Brunel from "../../images/Affiliations/web logos_Brunel.jpg";
import Cisco from "../../images/Affiliations/web logos_Cisco.jpg";
import Digilearning from "../../images/Affiliations/web logos_DigiL.jpg";
import esa from "../../images/Affiliations/web logos_ESA.jpg";
import FutureTechLabs from "../../images/Affiliations/web logos_FTL.jpg";
import Fuze from "../../images/Affiliations/web logos_FUZE.jpg";
import MettleCapital from "../../images/Affiliations/web logos_Mettle Cap.jpg";
import nlt from "../../images/Affiliations/web logos_NLT.jpg";
import nso from "../../images/Affiliations/web logos_NSO.jpg";
import Sega from "../../images/Affiliations/web logos_SEGA.jpg";
import TamingGaming from "../../images/Affiliations/Taming Gaming.jpg";
import TeamGB from "../../images/Affiliations/web logos_Team GB.jpg";
import un from "../../images/Affiliations/web logos_UN.jpg";
import Vedex from "../../images/Affiliations/web logos_VEDX.jpg";

export default function Sponsors() {
  const partners = [
    un,
    Brunel,
    Sega,
    TamingGaming,
    FutureTechLabs,
    Vedex,
    MettleCapital,
    Cisco,
    Digilearning,
    esa,
    Fuze,
    nlt,
    nso,
    TeamGB,
  ];
  return (
    <div className="sponsors-container" id="#partners">
      <div className="sponsors-title">
        <h2>Our Affiliations</h2>
      </div>
      <div className="sponsors-display">
        {partners.map((image, index)=>{
          return(
            
              <img key={index} 
              className="sponsors-logo"
              src={image} alt="sponsors"
              />
            
          )
        })}
      </div>
    </div>
  );
}
