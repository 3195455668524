import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

import logo from "../../images/checkpoint_logos/Checkpoint-logo.png";

import "../../css/footer.css";

function Footer() {
  return (
    <div className="footer-container">
      <div className="column1">
        <a href="/">
        <img className="footer-logo" src={logo} alt="checkpoint's logo" />
        </a>
        <div className="social-media-icon">
          <a href="https://twitter.com/CheckpointMZine">
          <FontAwesomeIcon
            icon={faTwitter}
            style={{ color: "var(--primary)", paddingRight: "5px" }}
          />
          </a>
          <a href="https://www.instagram.com/checkpointmzine/">
          <FontAwesomeIcon
            icon={faInstagram}
            style={{ color: "var(--primary)", paddingRight: "5px" }}
          />
          </a>
          <a href="https://www.facebook.com/checkpointmagazine/">
          <FontAwesomeIcon
            icon={faFacebook}
            style={{ color: "var(--primary)", paddingRight: "5px" }}
          />
          </a>
          <a href="https://www.linkedin.com/company/checkpointmagazine/?originalSubdomain=uk">
          <FontAwesomeIcon
            icon={faLinkedin}
            style={{ color: "var(--primary)", paddingRight: "5px" }}
          />
          </a>
          <a href="https://www.youtube.com/channel/UCzlpBWWUpXQJ8nqABMMlDKA">
          <FontAwesomeIcon
            icon={faYoutube}
            style={{ color: "var(--primary)", paddingRight: "5px" }}
          />
          </a>
        </div>
      </div>
      <div className="column2">
        <a href="https://checkpointmagazine.com/" className="footer-link">
          Magazine
        </a>
        <a href="/kidsmain/magazine" className="footer-link">
          Kids Magazine
        </a>
        <a href="https://checkpointmagazine.com/checkpoint-magazine-podcast/" className="footer-link">
          Podcast
        </a>
        <a href="https://www.oculus.com/experiences/quest/1976071352472244/?ranking_trace=0_1976071352472244_QUESTSEARCH_d2ab019e-856e-4687-85ef-507f4a706e01" className="footer-link">
          Multiverse
        </a>
        <a href="https://checkpointmerchandise.com/" className="footer-link">
          Shop
        </a>
      </div>
      <div className="column3">
        <a href="https://checkpointmagazine.com/about-us/" className="footer-link">
          About Us
        </a>
        <a href="#Sponsors" className="footer-link">
          Affiliations
        </a>
        <a href="https://checkpointhub.io/" className="footer-link">
          Blockchain
        </a>
        <a href="https://checkpoint-magazine.gitbook.io/checkpoint-orange-paper/" className="footer-link">
          Orange Paper
        </a>
        <a href="https://checkpointmagazine.com/videogame-sector-esg-report-white-paper/" className="footer-link">
          Research
        </a>
        <a href="https://checkpointmagazine.com/privacy-1/" className="footer-link">
          Privacy Policy
        </a>
      </div>
      <div className="column4">
        <a href="#Contact" className="footer-link">
          Contact Us
        </a>
        <div className="footer-contacts-line">
          <a href="mailto:info@magdoosmedia.com?subject=Checkpoint Advertising Query">
          <FontAwesomeIcon
            icon={faEnvelope}
            style={{ color: "var(--primary)" }}
          />
          </a>
          <p className="footer-contacts-title">Advertising |</p>
          <p className="footer-contacts-body"> info@magdoosmedia.com </p>
        </div>
        <div className="footer-contacts-line">
          <a href="mailto:kids@magdoosmedia.com?subject=Checkpoint Kids Query">
          <FontAwesomeIcon
            icon={faEnvelope}
            style={{ color: "var(--primary)" }}
          />
          </a>
          <p className="footer-contacts-title">Checkpoint Kids | </p>
          <p className="footer-contacts-body">kids@magdoosmedia.com</p>
        </div>
        <div className="footer-contacts-line">
          <a href="mailto:checkpoint@magdoosmedia.com?subject=Checkpoint General Query">
            <FontAwesomeIcon
              icon={faEnvelope}
              style={{ color: "var(--primary)" }}
            />
             </a>
            <p className="footer-contacts-title">General |</p>
            <p className="footer-contacts-body">checkpoint@magdoosmedia.com</p>
         
        </div>
      </div>
      <div className="column5">
      <div className="copyright">
          <p>© 2023 Magdoos Media Limited - All Rights Reserved.</p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
