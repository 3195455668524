import React from "react";
import AliceCarousel from "react-alice-carousel";
import ShopIcon from "../../commonStructure/ShopIcon";

import mobilephonecase from "../../images/shop/mobile_phone_case.webp";
import keyring_dolphin from "../../images/shop/keyring_dolphin.webp";
import hoodie_child from "../../images/shop/hoodie_child.webp";
import mug_whaleshark from "../../images/shop/mug_whaleshark.webp";
import spidercrab_magnet from "../../images/shop/spidercrab_magnet.webp";

import "react-alice-carousel/lib/alice-carousel.css";
import "../../css/home.css"
import "../../css/shopicon.css";
import "../../css/shop.css";

function Shop() {
  const items = [
    <ShopIcon product={mobilephonecase} />,
    <ShopIcon product={keyring_dolphin} />,
    <ShopIcon product={hoodie_child} />,
    <ShopIcon product={mug_whaleshark} />,
    <ShopIcon product={spidercrab_magnet} />,
  ];

  const responsive = {
    0: {
      items: 1,
    },
    500: {
      items: 2,
    },
    800: {
      items: 3,
    },
    1024: {
      items: 4,
    },
  };
  return (
    <div className="shop-container" id="#shop">
      <div className="shop-text">
        <h1>Checkpoint Store</h1>
        <p>
          A range of gifts based on the popular Checkpoint Bedtime Stories
          podcasts
        </p>
      </div>
      <div className="shop-display">
        <AliceCarousel
          mouseTracking
          items={items}
          responsive={responsive}
          infinite
          autoPlay
          disableButtonsControls
          animationDuration={3000}
          // renderPrevButton={() => {
          //   return (
          //     <div className="shop-left-arrow">
          //       <FontAwesomeIcon
          //         icon={faAngleLeft}
          //       />
          //     </div>
          //   );
          // }}
          // renderNextButton={() => {
          //   return (
          //     <div className="shop-right-arrow">
          //       <FontAwesomeIcon icon={faAngleRight} />
          //     </div>
          //   );
          // }}
        />
      </div>
      <div className="shop-link">
        <a href="https://checkpointmerchandise.com/">
          <p>Shop</p>
        </a>
      </div>
    </div>
  );
}

export default Shop;
