import React, { useState, useEffect, useRef } from "react";
import ReactGA from "react-ga4";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import Dropzone from "react-dropzone";
import "../../css/Kids/submissions.css";
import { faCloudArrowUp, faXmark } from "@fortawesome/free-solid-svg-icons";
import modalimg from "../../images/checkpoint_logos/Checkpoint®_Kids stacked_green on white.png";
import { AnalyticsEvent } from "../Analytics";

function Submissions() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [age, setAge] = useState("");
  const [email, setEmail] = useState("");
  const [parentName, setParentName] = useState("");
  const [files, setFiles] = useState([]);
  const [consentChecks, setConsentChecks]= useState({
    check1: false,
    check2: false,
    check3: false,
    check4: false,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const modalRef = useRef(null);
  
  const [isFormValidCheckbox, setIsFormValidCheckbox] = useState(true);
  const [isFormValidFiles, setIsFormValidFiles] = useState(true);
  const [isFormValidFields, setIsFormValidFields] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(()=>{
    const centerModal = () => {
      const modalWindow = modalRef.current;
      if(modalWindow){
        const { clientWidth, clientHeight } = modalWindow;
        modalWindow.style.left = `${(window.innerWidth - clientWidth)/2}px`;
        modalWindow.style.top = `${(window.innerHeight - clientHeight)/2}px`;
      }
    };

    centerModal();

    window.addEventListener("resize", centerModal);


    return ()=>{
      window.removeEventListener("resize", centerModal);

    };
  }, []);

  useEffect(() => {
   
    ReactGA.send({ hitType: "pageview", page: "/kidsmain/submissions", title: "Kids Submissions" });
  }, []);

  const toggleModal = () => {
    setModalOpen((prevValue) => !prevValue);
  }


  const handleFileChange = (acceptedFiles) => {
    setFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    console.log(`Checkbox ${name} is now ${checked}`);
    setConsentChecks((prevChecks)=>({
      ...prevChecks,
      [name]: checked,
    }));
  };

  const handleRemoveFile = (event, index) => {
    //stop propagation to prevent add file window to open when remove file button is clicked
    event.stopPropagation();
    setFiles((prevFiles)=> prevFiles.filter((_, i)=> i !== index));
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    //submit button shows data being processed
    setIsSubmitting(true);

    const formData = new FormData();
    formData.append("firstName", firstName);
    formData.append("lastName", lastName);
    formData.append("age", age);
    formData.append("parentName", parentName);
    formData.append("email", email);

    if (files) {
      files.forEach((fileItem) => {
        formData.append("file[]", fileItem);
      });
    }

    const minimumOneCheckbox = Object.values(consentChecks).some((isChecked)=> isChecked); 
    const isFieldsValid =
    firstName.length > 0 &&
    lastName.length > 0 &&
    Number(age) !== 0 &&
    parentName.length > 0 &&
    email != "";
//     if(firstName.length === 0 && lastName.length === 0 && age === "0" && parentName.length === 0 && email === ""){
// setIsFormValidFields(false);
// return;
if(!isFieldsValid){
setIsFormValidFields(false);
return;

    } else if (!minimumOneCheckbox){
      setIsFormValidCheckbox(false);
      return;
    } else if(files.length === 0 || files.length > 4){
      setIsFormValidFiles(false);
      return;
    } else {
      setIsFormValidCheckbox(true);
      setIsFormValidFiles(true);
      setIsFormValidFields(true);
    }
//convert checks into array
    const consentChecksArray =
      Object.entries(consentChecks);
    
//append into formData
    consentChecksArray.forEach(([name, value])=>{
      formData.append(name, value);
    });

    axios
      .post("/send-email/", formData)
      .then((response) => {
        console.log("Email sent:", response.data.message);
        //show modal 
        toggleModal();
        AnalyticsEvent("Submission", "Kids submission", email);
        //Reset the form after successful submission
        setFirstName("");
        setLastName("");
        setAge("0");
        setEmail("");
        setParentName("");
        setFiles([]);
        setConsentChecks({
          check1: false,
          check2: false,
          check3: false,
          check4: false,
        });
        setIsSubmitting(false);
       
      })
      .catch((error) => {
        console.error("Error sending email:", error);
        setIsSubmitting(false);
      });
  };


  return (
    <div className="submissions-cnt">
      <div className="submissions-header">
        <h1>Kids Permission Form</h1>
        <div>
          <p>
            Encouraging children to learn through play is at the heart of
            Checkpoint Kids Magazine. We provide an excellent platform to
            showcase their journey and celebrate individual creativity. Whether
            it’s written, crafted or drawn, filmed, photographed or recorded, we
            can showcase it all!{" "}
          </p>
          <p>
            We welcome submissions from children of any age, and we look forward
            to sharing their endeavours with our online readership. We will,
            however, need completed permission slips in order to share names,
            voices and in some cases, faces.{" "}
          </p>
          <p>
            It is essential that Checkpoint Kids magazine is a safe space for
            children, and we will therefore not publish any material containing
            names, faces or voices without completed permission forms from an
            appropriate adult. To have your child’s work featured in the
            magazine or across the site, please complete the form below.
          </p>
        </div>
      </div>
      <div className="submissions-form">
        <form onSubmit={handleSubmit}>
          <h2>Child permission slip</h2>
          <div className="submissions-form-ch">
            <label className="submissions-form-label">First Name</label>
            <input
              name="firstName"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
            <label className="submissions-form-label">Last Name</label>
            <input name="lastName"
            value={lastName}
            onChange={(e)=> setLastName(e.target.value)}/>
            <label className="submissions-form-label">Age</label>
            <select type="select" name="age" onChange={(e)=>setAge(e.target.value)}>
              <option value="0">Select age</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
              <option value="10">10</option>
              <option value="11">11</option>
              <option value="12">12</option>
              <option value="13">13</option>
              <option value="14">14</option>
              <option value="15">15</option>
              <option value="16">16</option>
            </select>
          </div>
          <h2>Parent/Guardian</h2>
          <div className="submissions-form-prt">
            <label className="submissions-form-label">Name</label>
            <input name="parentName"
            value={parentName}
            onChange={(e)=>setParentName(e.target.value)}/>

            <label className="submissions-form-label">
              Email
            </label>
            <input
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          {!isFormValidFields && <p style={{color: "red", fontSize: "12px"}}>Please complete all the fields.</p>}
          <h2>Consent Checks</h2>
          <div className="submission-form-checks">
            <div className="submission-form-check-row">
              <input type="checkbox" name="check1" checked={consentChecks.check1} onChange={handleCheckboxChange}/>
              <p className="submission-form-check-text">
                I consent to my child's work (written, audio and visual) to be
                featured in Checkpoint Magazine KIDS - they will be credited.
              </p>
            </div>
            <div className="submission-form-check-row">
              <input type="checkbox" name="check2" checked={consentChecks.check2} onChange={handleCheckboxChange}/>
              <p className="submission-form-check-text">
                I consent to my child's art work (photo, still or video) to be
                used in Checkpoint Magazine KIDS - they will be credited.
              </p>
            </div>
            <div className="submission-form-check-row">
              <input type="checkbox" name="check3" checked={consentChecks.check3} onChange={handleCheckboxChange}/>
              <p className="submission-form-check-text">
                I consent to my child's voice to be used in Checkpoint Magazine
                KIDS - they will be credited.
              </p>
            </div>
            <div className="submission-form-check-row">
              <input type="checkbox" name="check4" checked={consentChecks.check4} onChange={handleCheckboxChange}/>
              <p className="submission-form-check-text">
                I consent to the use of my child's content, but I DO NOT consent
                to their name being used - they will NOT be credited.
              </p>
            </div>
            {!isFormValidCheckbox &&<p style={{color: "red", fontSize: "12px"}}>You must selection at least one option</p>}
            
          </div>

          <h2>File upload</h2>

          <div className="submission-form-dropzone">
            <Dropzone onDrop={handleFileChange}>
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div {...getRootProps()} className="submission-form-dropzone-info">
                    <input {...getInputProps()} />
                    <FontAwesomeIcon icon={faCloudArrowUp} size="2xl" className="submission-cloud"/>
                    <p className="submission-dropzone-info-text">
                      Drag and drop or click to select files.
                    </p>
                    <ul className="submission-dropzone-files">
                      {files.map((file, index) => (
                        <li key={index} > 
                         
                        <div className="submission-dropzone-previewimg">
                        {file.type.startsWith("image/") && (
                          <img src={URL.createObjectURL(file)} alt={`Preview ${index}`} />
                        )}
                        <p className="submission-dropzone-img-name">
                         {file.name}
                         </p>
                         <button onClick={(event)=> handleRemoveFile(event, index)} className="submission-dropzone-remove-btn">
                          <FontAwesomeIcon icon={faXmark} size="lg" className="submission-dropzone-removefile"/>
                        </button>
                        </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </section>
              )}
            </Dropzone>
          </div>
          <p className="submission-form-capacity-warn">Maximum of 5 files per submission.</p>
{!isFormValidFiles && <p style={{color: "red", fontSize: "12px"}}>You need to submit at least one file and maximum of 5 files.</p>}
          
          <div className="submission-form-btn-cnt">
          <button type="submit" className={`submission-form-btn ${isSubmitting ? 'loading-animation' : ''}`}>
            {isSubmitting ? "Submitting...": "Submit"}
          </button>
          </div>
        </form>
      </div>
      {modalOpen && (
        <div className="submission-modal">
          <div className="submission-modal-content">
            
            <FontAwesomeIcon icon={faXmark} className="submission-modal-xmark" onClick={toggleModal}/>
           
            <span className="submission-modal-content-text">
            <h2>Form submitted successfully</h2>
            <img src={modalimg} alt="girl and dolphin"/>
            <p>Thank you for submitting your work.</p>
            <button className="submission-modal-backl-btn" onClick={toggleModal}>Go back</button>
            </span>
            
            </div>
        </div>
      )}
    </div>
  );
}

export default Submissions;
