import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import faPlayPause, { faPlay, faPause } from "@fortawesome/free-solid-svg-icons";
import {HiPlayPause} from "react-icons/hi2";
import {HiPlay, HiPause, HiRewind, HiFastForward} from "react-icons/hi";
import axios from "axios";
import "../../css/Kids/player.css";

function Player({selectedMusic}) {

    const [isPlaying, setIsPlaying] = useState(false);
    const [duration, setDuration] = useState(0);
    const [currentTime, setCurrentTime] = useState(0);

    const audioPlayer = useRef(null); //reference to audio player
    const progressBar = useRef();  //reference to progress bar
    const animationRef = useRef(); //reference to knob animation

    useEffect(() => {
        if (selectedMusic) {
          audioPlayer.current.src = selectedMusic.path;
          audioPlayer.current.load();
          //call play only when is ready to play otherwise will be loading in the back
          audioPlayer.current.addEventListener("canplaythrough", handleCanPlayThrough);
          setIsPlaying(true);
    }
    return () => {
      if (audioPlayer.current) {
        audioPlayer.current.removeEventListener("canplaythrough", handleCanPlayThrough);
      }
    };
  }, [selectedMusic]);

    useEffect(()=>{
        const handleLoadedMetadata = () => {
        const seconds = Math.floor(audioPlayer.current.duration);
        setDuration(seconds);
        progressBar.current.max = seconds;
    };
    audioPlayer.current.addEventListener("loadedmetadata", handleLoadedMetadata);

    return () => {
      if (audioPlayer.current) {
        audioPlayer.current.removeEventListener(
          "loadedmetadata",
          handleLoadedMetadata
        );
      }
    };
  }, []);

    useEffect(() => {
      const handleTimeUpdate = () => {
        if(audioPlayer.current){
          setCurrentTime(audioPlayer.current.currentTime);

        }
        
      };
    audioPlayer.current.addEventListener("play", whilePlaying);
      audioPlayer.current.addEventListener("timeupdate", handleTimeUpdate);
     
      return () => {
        if (audioPlayer.current) {
          audioPlayer.current.removeEventListener(
            "timeupdate",
            handleTimeUpdate
          );
          audioPlayer.current.removeEventListener("play", whilePlaying);
        }
      };
    }, [audioPlayer.current]);;

    const handleCanPlayThrough = () => {
      audioPlayer.current.play();
      setDuration(audioPlayer.current.duration);
     // progressBar.current.style.setProperty("--knob-position", "0%");
    };

    const calcTime = (secs) => {
        if (isNaN(secs)) {
            return '00:00';
          }
        const minutes = Math.floor(secs/60);
        const returnedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
        const seconds = Math.floor(secs % 60);
        const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
        return `${returnedMinutes}:${returnedSeconds}`; 
    }
    const togglePlayPause = () => {
        const prevValue = isPlaying;
        setIsPlaying(!prevValue);
        if(prevValue){
            audioPlayer.current.pause();
            cancelAnimationFrame(animationRef.current);
        } else {
            audioPlayer.current.play();
            animationRef.current = requestAnimationFrame(whilePlaying);
        }
        return !prevValue;
    }

    const whilePlaying = () => {
      if (audioPlayer.current) {
        setCurrentTime(audioPlayer.current.currentTime);
        const progress = (audioPlayer.current.currentTime / audioPlayer.current.duration) * 100;

        
        // Calculate the knob position based on the progress
    
     progressBar.current.style.setProperty("--seek-before-width", `${progress}%`);
     progressBar.current.style.setProperty("--knob-position", `${progress}%`);
 

        if (audioPlayer.current.ended) {
          setIsPlaying(false);
        }
      }
    
      if (audioPlayer.current && !audioPlayer.current.paused) {
        animationRef.current = requestAnimationFrame(whilePlaying);
      } else {
        cancelAnimationFrame(animationRef.current);
      }
    
  }

    const changePlayerCurrentTime = () => {
       const value = progressBar.current.value;
       const progress = (value / duration) * 100;
       audioPlayer.current.currentTime = value;
       progressBar.current.style.setProperty("--seek-before-width", `${progress}%`);
       progressBar.current.style.setProperty("--knob-position", `${progress}%`);
       setCurrentTime(value);
     
       // Add the following code to trigger the knob transition
      //  progressBar.current.setAttribute("data-dragging", "true");
      //  setTimeout(() => {
      //    progressBar.current.setAttribute("data-dragging", "false");
      //  }, 0);

    }

    const backThirty = () => {
        progressBar.current.value = Number(progressBar.current.value - 30);
       // changeRange();
    }

    const forwardThirty = () => {
        //save the current audio otherwise it will automatically jump to the end
        const currentAudioTime = audioPlayer.current.currentTime;
        const newTime = Math.min(currentAudioTime + 30, duration);
      
        setIsPlaying(false);
        setCurrentTime(newTime);
        audioPlayer.current.currentTime = newTime;
      
        setTimeout(() => {
          setIsPlaying(true);
          if (currentAudioTime >= newTime - 1) {
            setIsPlaying(false);
            audioPlayer.current.pause();
          }
        }, 50);
};
    


  return (<div className="player">
    <audio ref={audioPlayer} preload="metadata">

    </audio>
    {/* <button className="pl-btn" onClick={backThirty}><HiRewind /></button> */}
    <button className="pl-btn" onClick={togglePlayPause}>
    {!isPlaying ? <FontAwesomeIcon icon={faPlay} size="lg" className="pl-btn-img"/> : <FontAwesomeIcon icon={faPause} size="lg" className="pl-btn-img"/> }    
     </button>
    {/* <button className="pl-btn" onClick={forwardThirty}><HiFastForward/></button> */}

    
<div className="pl-range-cnt">
<div className="pl-time">{calcTime(currentTime)}</div>
    <input className="pl-range" type="range" defaultValue="0" ref={progressBar} 
    onChange={changePlayerCurrentTime}
    style={{ "--seek-before-width": `${(currentTime / duration) * 100}%` }}
    />
    
    <div className="pl-time">{(duration && !isNaN(duration)) && calcTime(duration)}</div>
    </div>
    
  </div>
  )
}

export default Player;
