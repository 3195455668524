import React from "react";
import learninglogo from "../../images/checkpoint_logos/learninglogo.png";
import magazinelearning from "../../images/Learning/magazine_learning.png";

import "../../css/checkpointlearning.css";

function CheckpointLearning() {
  return (
    <div>
      <div className="learning-container">
        <div className="learn-content-container">
          <div className="learning-image">
            <img
              src={magazinelearning}
              alt="checkpoint learning exercises sheets"
            />
          </div>
          <div className="learn-description">
            <h1>Checkpoint Learning</h1>
            <p>
              Free lesson plans inspired by videogames. Each pack contains
              approximately three 45-minute lessons but there are opportunities
              to expand and develop them in a variety of interesting ways.
            </p>
            <a href="/kidsmain/kidsmainslide" className="learning-link">
              <img
                className="checkpoint-ln-link"
                src={learninglogo}
                alt="checkpoint learning logo"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CheckpointLearning;
