import React, {useEffect} from "react";
import ReactGA from "react-ga4";
import AliceCarousel from "react-alice-carousel";

import "react-alice-carousel/lib/alice-carousel.css";
import kmlogo from "../../images/checkpoint_logos/kidslogo.png";
import applestore from "../../images/play_store/applestore.png";
import googleplay from "../../images/play_store/googleplay.png";
import pocketmags from "../../images/pocketmags_button.jpeg";

import magazinekidsData from "../../data/magazinekids.json";
import "../../css/Kids/kidsmainmagazine.css";
import Covers from "./Covers";

function KidsmainMagazine() {

  useEffect(() => {
    // Track the page view whenever the location (URL) changes
    ReactGA.send({ hitType: "pageview", page: "/kidsmain/magazine", title: "Checkpoint Kids Magazine" });
  }, []);

  const items2 = 
   magazinekidsData.map((item, index)=>(

  <div key={index}>
    <Covers webm={item.webm}
    mp4={item.mp4}
    issue={item.issue}
    number={item.title}/>
  </div>


   ))

  const responsive = {
    0: {
      items: 1,
    },
    400: {
      items: 2,
    },
    600: {
      items: 3,
    },
    900: {
      items: 4,
    },
  };
  return (
    <div className="kmm-cnt">
      <div className="km-header">
        <img src={kmlogo} alt="kidslogo" />
        <div className="km-text">
          <p>
            At Checkpoint we believe that video gaming is a voyage of
            self-discovery. An adventure that challenges and pushes you beyond
            your limitations. Gaming opens a door to all the wonders of the
            world: art, history, science, engineering, technology… the list is
            endless. And you can be part of that world.
          </p>
          <p>
            Checkpoint Kids is made for you. It’s packed with puzzles, fun
            facts, mini-challenges and creative activities, all inspired by the
            games we feature.{" "}
          </p>
        </div>
      </div>
      <div className="kmm-virtual">
        <a href="https://play.google.com/store/apps/details?id=com.jellyfish.checkpointkids&hl=en_US&gl=US">
          <img src={googleplay} alt="google play link" />
        </a>
        <a href="https://apps.apple.com/gb/app/checkpoint-magazine-kids/id1522005435">
          <img src={applestore} alt="apple store link" />
        </a>
        <a href="https://pocketmags.com/checkpoint-kids-magazine">
          <img src={pocketmags} alt="pocketmags magazines link" />
        </a>
      </div>
      <div className="kmm-mag-carousel">
        <AliceCarousel
          mouseTracking
          responsive={responsive}
          items={items2}
          infinite
          autoPlay
          disableButtonsControls
          animationDuration={8000}
        />
      </div>
      <div className="kmm-advise">
        <p>
          Please note: If using the Pocket Mags website, you will need to
          register (FREE) on their site before you’ll be able to download the
          magazine. However, you can just download the official Checkpoint
          Magazine app directly from your app store with no need to sign-up or
          register.
        </p>
      </div>
    </div>
  );
}

export default KidsmainMagazine;
