import React from "react";
import "../css/portals.css";

export default function Portals({ portal, link }) {
  return (
    <div className="portal-container">
      <div className="portal">
        <a href={link}>
          <img src={portal} alt="portals" />
        </a>
      </div>
    </div>
  );
}
