import React, { useEffect, useState, useRef } from "react";
import Modal from "react-modal";
import { AnalyticsEvent } from "../Analytics";
import copyright from "../../images/kids/Copyright_page-0001.jpg";
import lessonData from "../../data/teacherlessons.json";
import { useParams } from "react-router-dom";
import "../../css/Kids/lessonpreview.css";

export default function TLPreview() {
 
  const { lessonId } = useParams();
  const [lesson, setLesson] = useState({});
  const [modal, setModal] = useState(false);

   //ref for the embed element
   const embedRef = useRef();

  useEffect(() => {
    const selectedLesson = lessonData.find((lesson) => lesson.id === lessonId);
    setLesson(selectedLesson);
  }, [lessonId]);

  //resize on render
  useEffect(() => {
    const resizePdfViewer = () => {
      const embedElement = embedRef.current;
      const container = embedElement.parentElement;
      const availableHeight = container.offsetHeight;
      embedElement.style.height = `${availableHeight}px`;
    };

    resizePdfViewer(); // Call the resize function initially

    window.addEventListener("resize", resizePdfViewer);

    
    return () => {
      window.removeEventListener("resize", resizePdfViewer);
    };
  }, []);

  const handleDownload = async () => {
    try {
      setModal(true);
      //Make a request to the json and convert it to a blob
      const response = await fetch(lesson.path);
      const blob = await response.blob();
      //Create a url for the blob
      const url = URL.createObjectURL(blob);
      //Create anchor tag and set blob url
      const link = document.createElement("a");
      link.href = url;
      link.download = "TPL_" + (Number(lesson.id) + 1);
      //click event initiates the download process
      link.click();
      AnalyticsEvent("Downloaded Teacher Lesson", "Teacher Lesson Download", lesson.title);
    } catch (error) {
      AnalyticsEvent("Downloaded Teacher Lesson", "Teacher Lesson Failed Download", lesson.title);
      console.error("Error downloading file: ", error);
    }
  };

  return (
    <div className="hl-main-cnt">
      <h2>Teacher Lesson Plans</h2>
      <div className="hl-content-cnt">
        <div className="hl-header">
          <div className="hl-pdf-description">
            <p>Read the index below to give an overview of the lesson plan.</p>
          </div>
        </div>
        <div className="hl-cnt">
          <div className="hl-index-preview">
            <embed
            ref={embedRef}
              src={lesson.index}
              type="application/pdf"
            />
          </div>
          <div className="hl-lesson-btn-cnt">
            <div className="hl-lesson-preview">
            <embed 
              ref={embedRef}
              src={lesson.preview}
              type="application/pdf"
            />
            </div>
            <div className="hl-content">
              <p>Subject: {lesson.subject}</p>
              <p>Theme: {lesson.theme}</p>
              <p>Age Group: {lesson.ageGroup}</p>
              <button className="hl-btn-dwnl" onClick={handleDownload}>
                Download pack
              </button>
              <Modal
                isOpen={modal}
                onRequestClose={() => setModal(false)}
                contentLabel="Image Popup"
                style={{
                  overlay: {
                    display: "flex",
                    justifyContent: "center",
                    margin: "20px 0 20px 0",
                    background: "none",
                    zIndex: 10,
                  },
                  content: {
                    width: "500px",
                    height: "650px",
                    display: "flex",
                    alignContent: "center",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "static",
                  },
                }}
              >
                <div>
                  <img src={copyright} 
                  style={{ height: "600px" }} 
                  />
                </div>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
