import React from "react";

import applestore from "../../images/play_store/applestore.png";
import googleplay from "../../images/play_store/googleplay.png";
import applepodcasts from "../../images/apple-podcasts-button.jpg";
import googlepodcasts from "../../images/google-podcasts-button.jpg";
import spotifypodcasts from "../../images/spotify-podcasts-button.jpg";
import bedtimestorieslogo from "../../images/checkpoint_logos/bedtimestorieslogo.png";
import girl_whale from "../../images/bedtime/Beyond_Blue_Girl+Dolphin.png";

import "../../css/bedtimestories.css";

export default function BedtimeStories() {
  return (
    <div className="bedtime-container" id="bedtime">
      <div className="bedtime-text">
        <h1>Checkpoint Bedtime Stories</h1>
        <p>
          Download the Bedtime stories app and have access to our FREE bedtime
          stories. Use it to browse stories by your favourite narrators, save
          them to your own personalised playlist and explore some of the
          exclusive music from your favourite tales.
        </p>
        <div className="child-whale">
          <img src={girl_whale} alt="whale and child" />
        </div>
        <a href="/kidsmain/bedtimestories">
          <img
            className="bt-link"
            src={bedtimestorieslogo}
            alt="bedtimestories logo"
          />
        </a>
        <div className="virtual-row">
          <a href="https://apps.apple.com/gb/app/kids-bedtime-stories/id1564788638">
            <img
              className="bedtime-virtualstore-link"
              src={applestore}
              alt="apple download link"
            />
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.checkpointkids.bedtimestories&hl=en&gl=US">
            <img
              className="bedtime-virtualstore-link"
              src={googleplay}
              alt="google play download link"
            />
          </a>
          <a href="https://podcasts.apple.com/gb/podcast/kids-bedtime-stories/id1511955778?mt=2&app=podcast">
            <img
              className="bedtime-virtualstore-link"
              src={applepodcasts}
              alt="apple podcasts link"
            />
          </a>
          <a href="https://podcasts.google.com/feed/aHR0cHM6Ly9mZWVkcy50cmFuc2lzdG9yLmZtL2tpZHMtYmVkdGltZS1zdG9yaWVz">
            <img
              className="bedtime-virtualstore-link"
              src={googlepodcasts}
              alt="google podcasts link"
            />
          </a>
          <a href="https://open.spotify.com/show/5AKbVWsN2R2YBVSscuBm5g">
            <img
              className="bedtime-virtualstore-link"
              src={spotifypodcasts}
              alt="spotify podcasts link"
            />
          </a>
        </div>
      </div>
      <div className="bedtime-player">
        <iframe
          title="player1"
          loading="lazy"
          src="https://share.transistor.fm/e/kids-bedtime-stories/playlist"
          width="auto"
          height="auto"
          frameBorder="no"
          scrolling="no"
          seamless=""
          data-mce-fragment="1"
        ></iframe>
      </div>
    </div>
  );
}
