import React from "react";
import magazine from "../../images/ipad/iPad-Pro_Magazine.png";
import magazineLogo from "../../images/checkpoint_logos/checkpointmagazinelogo.png";
import applestore from "../../images/play_store/applestore.png";
import googleplay from "../../images/play_store/googleplay.png";
import pocketmags from "../../images/pocketmags_button.jpeg";

import "../../css/checkpointmagazine.css";

function CheckpointMagazine() {
  return (
    <div className="mag-container" id="#magazine">
      <div className="mag-text">
        <h1>Checkpoint Magazine</h1>
        <p>
          Download the Checkpoint app <br />
          and have access to our FREE magazine
        </p>
        <a href="https://checkpointmagazine.com/">
        <img className="mg-link" src={magazineLogo} alt="magazine logo" />
        </a>
        <div className="magazine-virtual">
          <a href="https://play.google.com/store/apps/details?hl=en_us&id=com.jellyfishconnect.checkpoint">
            <img
              className="virtualstore-link"
              src={googleplay}
              alt="google play link"
            />
          </a>
          <a href="https://apps.apple.com/us/app/id1215293310">
            <img
              className="virtualstore-link"
              src={applestore}
              alt="apple store link"
            />
          </a>
          <a href="https://pocketmags.com/checkpoint-magazine">
            <img
              className="virtualstore-link"
              src={pocketmags}
              alt="pocketmags magazines link"
            />
          </a>
        </div>
      </div>
      <img className="mag-img" src={magazine} alt="checkpoint magazine cover" />
    </div>
  );
}

export default CheckpointMagazine;
