import React, {useEffect} from "react";
import ReactGA from "react-ga4";
import KidsNavbar from "../../components/Kids/KidsNavbar.jsx";
import Footer from "../../components/Homepage/Footer.jsx";
import "../../css/Kids/kidsmain.css";
import { Outlet } from "react-router-dom";

function KidsMain() {

  useEffect(() => {
    
    ReactGA.send({ hitType: "pageview", page: "/kidsmain/", title: "Checkpoint Kids Home" });
  }, []);
  return (
    <div className="kidsmain-cnt">
      <div className="kids-nvb">
        <KidsNavbar />
      </div>
      <div className="kids-content">
        <Outlet />
      </div>
      <div className="kids-footer">
        <Footer />
      </div>
    </div>
  );
}

export default KidsMain;
