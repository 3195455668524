import React, { useEffect, useState, useRef } from "react";
import ReactGA from "react-ga4";
import Modal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import createData from "../../data/create.json";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import learnlogo from "../../images/checkpoint_logos/learninglogo.png";
import "../../css/Kids/create.css";
import ModalPreview from "./ModalPreview";

export default function Create() {
  const [hoveredImg, setHoveredImg] = useState(null);
  const [selectedImg, setSelectedImg] = useState(null);
  const { createFileId } = useParams();
  const [createFile, setCreateFile] = useState({});
  const [modal, setModal] = useState(false);
  
  

  useEffect(() => {
    const selectedCreatefile = createData.find(
      (create) => create.id === createFileId
    );
    setSelectedImg(selectedCreatefile);
  }, [createFileId]);

  useEffect(() => {
   
    ReactGA.send({ hitType: "pageview", page: "/kidsmain/create", title: "Checkpoint Create" });
  }, []);

  const handleMouseEnter = (index) => {
    setHoveredImg(index);
  };

  const handleMouseLeave = () => {
    setHoveredImg(null);
  };

  const handleSelectedImg = (img) => {
    setSelectedImg(img);
    setModal(true);
  };

  const handleClose = () => {
    setModal(false);
    setSelectedImg(null);
    
  };
 
  return (
    <div className="tl-cnt">
      <div className="tl-header">
        <div className="tl-title">
          <img src={learnlogo} alt="checkpoint learning logo" />
          <h1>Create</h1>
        </div>
        <div className="tl-gallery-main">
          <div className="tl-dsp">
            <p>
              Why not try one of these engaging activities? Each one comes from
              an issue of the magazine. The download link gives the Challenge
              No, Issue and Page No – C2 I5 P15.
            </p>
          </div>
        </div>
      </div>
      <div className="tl-gallery">
        {createData.map((item, index) => (
          <div
            key={index}
            className={`tl-image-container ${
              hoveredImg === index ? "tl-hovered" : ""
            }`}
            onMouseEnter={() => handleMouseEnter(index)}
            onMouseLeave={handleMouseLeave}
            onClick={() => handleSelectedImg(item)}
          >
            {hoveredImg === index && (
              <div className="tl-hover">
                <p>{item.title}</p>
                <p>Theme: {item.theme}</p>
              </div>
            )}
            <img src={item.preview} alt={`lesson ${index + 1} cover`} />
          </div>
        ))}
      </div>

      <div onClick={handleClose}>
        {selectedImg && (
          <ModalPreview
            image={selectedImg}
            
          />
        )}
      </div>
    </div>
  );
}
