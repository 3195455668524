import React from "react";
import "../css/multiverseshots.css"

function MultiverseShots({ screenshot }) {
  return (
    <div className="multiverseshots-container">
      <div className="shot-container">
        <img className="screenshot" src={screenshot} alt="multiverse shots" />
      </div>
    </div>
  );
}

export default MultiverseShots;
