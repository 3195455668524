import React, {useEffect} from "react";
import ReactGA from "react-ga4";
import km1 from "../../images/kids/kidsmain/Link buttons_CKids.png";
import km2 from "../../images/kids/kidsmain/Link buttons_BS.png";
import km3 from "../../images/kids/kidsmain/Link buttons_TeacherLP.png";
import km4 from "../../images/kids/kidsmain/Link buttons_HomeLM.png";
import km5 from "../../images/kids/kidsmain/Link buttons_PS.png";
import km6 from "../../images/kids/kidsmain/Link buttons_create.png"
import kidslg from "../../images/checkpoint_logos/Checkpoint-Kids-Logo-600px.png";

import "../../css/Kids/kidsmainslide.css";

function KidsmainSlide() {

  useEffect(() => {
    
    ReactGA.send({ hitType: "pageview", page: "/kidsmain/kidsmainslide", title: "Checkpoint Kids Home" });
  }, []);

  return <div className="kids-slide">
    <img className="ks-kidslogo" src={kidslg} alt="kids logo"/>
    <div className="kids-slide-cnt">
  <a href="/kidsmain/magazine">
  <img className="kids-slide-img" src={km1} alt="kids magazine link"/>
  </a>
  <a href="/kidsmain/bedtimestories">
  <img className="kids-slide-img" src={km2} alt="bedtime stories link"/>
  </a>
  <a href="/kidsmain/teacherlessons">
  <img className="kids-slide-img" src={km3} alt="teacher lesson plans link"/>
  </a>
  <a href="/kidsmain/homelearning">
  <img className="kids-slide-img" src={km4} alt="home learning materials link"/>
  </a>
  <a href="/kidsmain/create">
    <img className="kids-slide-img" src={km6} alt="create link"/>
  </a>
  <a href="/kidsmain/submissions">
    <img className="kids-slide-img" src={km5} alt="submissions link"/>
  </a>
  </div>
  </div>;
}

export default KidsmainSlide;
