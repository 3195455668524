import React, {useEffect} from "react";
import ReactGA from "react-ga4";
import Brunel from "../../images/kids/friends/1x_colour/web logos_Brunel_colour.jpg";
import Cisco from "../../images/kids/friends/1x_colour/web logos_Cisco_colour.jpg";
import Digilearning from "../../images/kids/friends/1x_colour/web logos_DigiL_colour.jpg";
import esa from "../../images/kids/friends/1x_colour/web logos_ESA_colour.jpg";
import FutureTechLabs from "../../images/kids/friends/1x_colour/web logos_FTL_colour.jpg";
import Fuze from "../../images/kids/friends/1x_colour/web logos_FUZE_colour.jpg";
import MettleCapital from "../../images/kids/friends/1x_colour/web logos_Mettle Cap_colour.jpg";
import nlt from "../../images/kids/friends/1x_colour/web logos_NLT_colour.jpg";
import nso from "../../images/kids/friends/1x_colour/web logos_NSO_colour.jpg";
import Sega from "../../images/kids/friends/1x_colour/web logos_SEGA_colour.jpg";
import TamingGaming from "../../images/kids/friends/1x_colour/web logos_Taming Gaming_colour.jpg";
import TeamGB from "../../images/kids/friends/1x_colour/web logos_Team GB_colour.jpg";
import un from "../../images/kids/friends/1x_colour/web logos_UN_colour.jpg";
import Vedex from "../../images/kids/friends/1x_colour/web logos_VEDX_colour.jpg";
import "../../css/Kids/friends.css";

function Friends() {

  useEffect(() => {
   
    ReactGA.send({ hitType: "pageview", page: "/kidsmain/friends", title: "Checkpoint Friends" });
  }, []);

  const friends = [
    un,
    Brunel,
    Sega,
    TamingGaming,
    FutureTechLabs,
    Vedex,
    MettleCapital,
    Cisco,
    Digilearning,
    esa,
    Fuze,
    nlt,
    nso,
    TeamGB,
  ];
  return (
    <div className="friends-cnt">
      <div className="friends-header">
        <h1>Our Friends</h1>
        </div>
      <div className="friends-text">
        <p> We are proud to be working with some of the most innovative, interesting
        and supportive companies, charities, organisations and agencies in the
        world. Below is a list of organisations we’re proud to call our
        “friends”. Our relationships with them allow us to provide our users
        with unique opportunities to discover more about the work they do and
        how they are related to the world of videogames.</p>
        <p> We want to build a
        real-life community of contributors, supporters and sponsors for our
        activities and use gaming as a vehicle for engagement with young people.
        If you’d like to find out more about Checkpoint Kids and how you or your
        organisation can be involved, get in touch with us now.</p>
       
      </div>
      <div className="friends-logos-cnt">
      {/* {friends.map((image, index)=>{
          return(
            
              <img key={index} 
              className="friends-logo"
              src={image} alt="sponsors"
              />
            
          )
        })} */}
        <img src={un} alt="United Nations" className="friends-logo"/>
        <img src={Brunel} alt="Brunel University" className="friends-logo"/>
        <img src={Sega} alt="Sega" className="friends-logo"/>
        <img src={Fuze} alt="Fuze" className="friends-logo"/>
        <img src={TamingGaming} alt="Taming Gaming" className="friends-logo"/>
        <img src={TeamGB} alt="team GB" className="friends-logo"/>
        <img src={Vedex} alt="Vedex" className="friends-logo"/>
        <img src={MettleCapital} alt="Mettle Capital" className="friends-logo"/>
        <img src={Cisco} alt="cisco" className="friends-logo"/>
        <img src={FutureTechLabs} alt="future tech labs" className="friends-logo"/>
        <img src={Digilearning} alt="digilearning" className="friends-logo"/>
        <img src={esa} alt="esa" className="friends-logo"/>
        
        <img src={nlt} alt="nlt" className="friends-logo"/>
        <img src={nso} alt="nso" className="friends-logo"/>

        
      
      </div>
    </div>
  );
}

export default Friends;
