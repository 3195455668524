import React, { useState, useEffect } from "react";
import ReactGA from "react-ga4";
import { AnalyticsEvent } from "../Analytics";
import bslogo from "../../images/checkpoint_logos/bedtimestorieslogo.png";
import applestore from "../../images/play_store/applestore.png";
import googleplay from "../../images/play_store/googleplay.png";
import applepodcasts from "../../images/apple-podcasts-button.jpg";
import googlepodcasts from "../../images/google-podcasts-button.jpg";
import spotifypodcasts from "../../images/spotify-podcasts-button.jpg";

import "../../css/Kids/kidsmainbedtimestories.css";
import musicData from "../../data/music.json";
import Player from "./Player";

function KidsmainBedtimeStories() {
  const [selectedMusic, setSelectedMusic] = useState(null);

  const handleMusic = (music) => {
    setSelectedMusic(music);
    AnalyticsEvent("Played music", "Listened Bedtime Story", music.name);
  };

  useEffect(() => {
    
    ReactGA.send({ hitType: "pageview", page: "/kidsmain/bedtimestories", title: "Checkpoint Bedtime Stories" });
  }, []);

  return (
    <div className="bs-cnt">
      <div className="bs-title">
        <img className="bs-logo" src={bslogo} alt="bedtime stories logo" />
        <div className="bs-title-text">
        <p>
          We’ve also got a dedicated app for the Bedtime Stories so it’s easy to
          stay up to date with our latest releases. You can browse stories by
          your favourite narrators and save them to your own personalised
          playlist while exploring the exclusive music that accompanies each
          one. You’ll also notice some un-activated features, which are coming
          soon.{" "}
        </p>
        <p>
          Like everything we do, it’s free. We’d appreciate it if you could
          rate, review and share.
        </p>
        </div>
      </div>
      <div className="bs-row">
        <a href="https://apps.apple.com/gb/app/kids-bedtime-stories/id1564788638">
          <img src={applestore} alt="apple download link" />
        </a>
        <a href="https://play.google.com/store/apps/details?id=com.checkpointkids.bedtimestories&hl=en&gl=US">
          <img src={googleplay} alt="google play download link" />
        </a>
        <a href="https://podcasts.apple.com/gb/podcast/kids-bedtime-stories/id1511955778?mt=2&app=podcast">
          <img src={applepodcasts} alt="apple podcasts link" />
        </a>
        <a href="https://podcasts.google.com/feed/aHR0cHM6Ly9mZWVkcy50cmFuc2lzdG9yLmZtL2tpZHMtYmVkdGltZS1zdG9yaWVz">
          <img src={googlepodcasts} alt="google podcasts link" />
        </a>
        <a href="https://open.spotify.com/show/5AKbVWsN2R2YBVSscuBm5g">
          <img src={spotifypodcasts} alt="spotify podcasts link" />
        </a>
      </div>
      <div className="bs-pl-cnt">
        <div className="bs-carousel"></div>
        <div className="bs-podcast-cnt">
          <div className="bs-podcast-title">
          <p>Click an image to listen to the story</p>
          </div>
          <div className="bs-podcast">
            {musicData.map((music) => (
              <div key={music.id} className="bs-podcast-episode">
                <img
                  className="bs-podcast-episode"
                  src={music.cover}
                  alt={music.name}
                  loading="lazy"
                  onClick={() => handleMusic(music)}
              
                />

                {selectedMusic && selectedMusic.id === music.id && (
                  <div className="bs-player">
                    <Player selectedMusic={selectedMusic} />
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default KidsmainBedtimeStories;
