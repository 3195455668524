import React from "react";

function ShopIcon({ product }) {
  return (
    <div className="shop-ic-container">
      <div className="shop-icon-container">
        <img className="product" src={product} alt="product" />
      </div>
    </div>
  );
}

export default ShopIcon;
