import React, { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import learnlogo from "../../images/checkpoint_logos/learninglogo.png";
import lessonData from "../../data/teacherlessons.json";
import "../../css/Kids/teacherlessons.css";
import { AnalyticsEvent } from "../Analytics";

export default function TeacherLessons() {
  const [hoveredImg, setHoveredImg] = useState(null);
  const [lesson, setLesson] = useState({});
  const { lessonId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const selectedLesson = lessonData.find((lesson) => lesson.id === lessonId);
    setLesson(selectedLesson);
  }, [lessonId]);

  useEffect(() => {
   
    ReactGA.send({ hitType: "pageview", page: "/kidsmain/teacherlessons", title: "Checkpoint Teacher Lessons" });
  }, []);

  const handleMouseEnter = (index) => {
    setHoveredImg(index);
  };

  const handleMouseLeave = () => {
    setHoveredImg(null);
  };
  const handleNavigate = (lessonId) => {
    navigate(`/kidsmain/teacherlessons/${lessonId}`);
    AnalyticsEvent("Opened lesson", "Clicked Teacher Lesson", "lesson "+(Number(lessonId)+1));
  };

  return (
    <div>
      <div className="tl-header">
        <div className="tl-title">
          <img src={learnlogo} alt="checkpoint learning logo" />
          <h1>Teacher Lesson Plans</h1>
        </div>
        <div className="tl-gallery-main">
          <div className="tl-dsp">
            <p>
              At Checkpoint Kids we’ve designed free lesson plans inspired by
              videogames for you to use in class. Everything you need is
              included in the pack: detailed lesson plans, resource materials,
              video clip links, worksheets, self-assessments etc.
            </p>
            <p>
              Each pack contains up to three 45-minute lessons and there are
              opportunities to expand and develop them in a variety of
              interesting ways. Although the lessons take their lead from games,
              there is no requirement to play or to have played them.
            </p>
          </div>
        </div>
        </div>
        <div className="tl-gallery">
          {lessonData.map((item, index) => (
            <div
              key={index}
              className={`tl-image-container ${
                hoveredImg === index ? "tl-hovered" : ""
              }`}
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={handleMouseLeave}
              onClick={() => handleNavigate(index)}
            >
              {hoveredImg === index && (
                <div className="tl-hover">
                  <p>Subject: {item.subject}</p>
                  <p>Theme: {item.theme}</p>
                  <p>Outcome: {item.outcome}</p>
                </div>
              )}
              <img src={item.image} alt={`lesson ${index + 1} cover`} />
            </div>
          ))}
        </div>
     
    </div>
  );
}
