import React, { useState, useEffect } from "react";
import ReactGA from "react-ga4";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import learnlogo from "../../images/checkpoint_logos/learninglogo.png";
import lessonData from "../../data/homelessons.json";
import "../../css/Kids/teacherlessons.css";
import { AnalyticsEvent } from "../Analytics";

export default function HomeLearning() {
  const [hoveredImg, setHoveredImg] = useState(null);
  const { lessonId } = useParams();
  const [lesson, setLesson] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const selectedLesson = lessonData.find((lesson) => lesson.id === lessonId);
    setLesson(selectedLesson);
  }, [lessonId]);

  useEffect(() => {
   
    ReactGA.send({ hitType: "pageview", page: "/kidsmain/homelearning", title: "Checkpoint Home Learning" });
  }, []);

  const handleMouseEnter = (index) => {
    setHoveredImg(index);
  };

  const handleMouseLeave = () => {
    setHoveredImg(null);
  };

  const handleNavigate = (lessonId) => {
    navigate(`/kidsmain/homelearning/${lessonId}`);
    AnalyticsEvent("Opened lesson", "Clicked Home Lesson", (Number(lessonId)+1));
  };

  return (
    <div>
      <div className="tl-header">
        <div className="tl-title">
          <img src={learnlogo} alt="checkpoint learning logo" />
          <h1>Home Learning Materials</h1>
        </div>
        <div className="tl-gallery-main">
          <div className="tl-dsp">
            <p>
              Time to get busy with our wide range of exciting activities to do
              at home. Inspired by the games we feature in each issue of the
              magazine, there’s something for everyone.
            </p>
            <p>
              Each pack contains all you need to complete the activity, but you
              may have to find some everyday materials if it includes crafting.
              You shouldn’t need much help from a grown-up, but you might want
              to have them on hand just in case!
            </p>
          </div>
        </div>
      </div>
      <div className="tl-gallery">
        {lessonData.map((item, index) => (
          <div
            key={index}
            className={`tl-image-container ${
              hoveredImg === index ? "tl-hovered" : ""
            }`}
            onMouseEnter={() => handleMouseEnter(index)}
            onMouseLeave={handleMouseLeave}
            onClick={() => handleNavigate(index)}
          >
            {hoveredImg === index && (
              <div className="tl-hover">
                <p>Subject: {item.subject}</p>
                <p>Theme: {item.theme}</p>
                <p>Outcome: {item.outcome}</p>
              </div>
            )}
            <img src={item.image} alt={`lesson ${index + 1} cover`} />
          </div>
        ))}
      </div>
    </div>
  );
}
