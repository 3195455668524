import React from "react";
import AliceCarousel from "react-alice-carousel";

import multiverse1 from "../../images/multiverse/multiverse1.png";
import multiverse2 from "../../images/multiverse/multiverse2.png";
import multiverse3 from "../../images/multiverse/multiverse3.png";
import multiverse4 from "../../images/multiverse/multiverse4.png";
import multiverse5 from "../../images/multiverse/multiverse5.png";
import multiverse6 from "../../images/multiverse/multiverse6.png";
import multiverse7 from "../../images/multiverse/multiverse7.png";
import multiverse8 from "../../images/multiverse/multiverse8.png";

import "react-alice-carousel/lib/alice-carousel.css";
import "../../css/multiverse.css";
import "../../css/home.css";
import MultiverseShots from "../../commonStructure/MultiverseShots";

const items = [
  <MultiverseShots screenshot={multiverse1} />,
  <MultiverseShots screenshot={multiverse2} />,
  <MultiverseShots screenshot={multiverse3} />,
  <MultiverseShots screenshot={multiverse4} />,
  <MultiverseShots screenshot={multiverse5} />,
  <MultiverseShots screenshot={multiverse6} />,
  <MultiverseShots screenshot={multiverse7} />,
  <MultiverseShots screenshot={multiverse8} />,
];

export default function Multiverse() {
  const responsive = {
    0: {
      items: 1,
    },
    500: {
      items: 2,
    },
    800:{
      items: 3,
    },
    900:{
      items: 4,
    },
    1024: {
      items: 3,
    },
  };
  return (
    <div className="multiverse-container" id="#multiverse">
      <div className="multiverse-text">
        <h1>Multiverse</h1>
        <p>
          Already available on Oculus and FREE, the multiverse facilitates
          engagement with content and others in the space
        </p>
      </div>
      <div className="multiverse-display">
        <AliceCarousel
          mouseTracking
          items={items}
          responsive={responsive}
          infinite
          autoPlay
          disableButtonsControls
          animationDuration={3000}
          
        />
   
      </div>
      <div className="oculus-link">
        <a href="https://www.oculus.com/group_launch/1114796679898828/"><p>Oculus</p>
        </a>
      </div>
    </div>
  );
}
