import React from "react";
import logo from "../../images/checkpoint_logos/Checkpoint-logo.png";
import logo2 from "../../images/checkpoint_logos/V2MastheadWebsite.WebM";
import logo3 from "../../images/checkpoint_logos/V2MastheadWebsite.mp4";
import AliceCarousel from "react-alice-carousel";

import "react-alice-carousel/lib/alice-carousel.css";
import "../../css/header.css";
import "../../css/home.css";
import Portals from "../../commonStructure/Portals";

import portal_bedtime from "../../images/Portals/BS_portal_web.png";
import portal_kids2 from "../../images/Portals/Kids_portal_web.png";
import portal_learning from "../../images/Portals/Learning_portal.png";
import portal_magazine from "../../images/Portals/Magazine_portal_web.png";
import portal_merchandise from "../../images/Portals/Merchandise_portal_web.png";
import portal_podcasts from "../../images/Portals/Podcasts_portal_web.png";

const items = [
  <Portals portal={portal_magazine} link="https://checkpointmagazine.com/" />,
  <Portals portal={portal_kids2} link="/kidsmain/kidsmainslide" />,
  <Portals portal={portal_learning} link="/kidsmain/kidsmainslide" />,
  <Portals
    portal={portal_bedtime}
    link="/kidsmain/bedtimestories"
  />,
  <Portals
    portal={portal_podcasts}
    link="https://checkpointmagazine.com/checkpoint-magazine-podcast/"
  />,
  <Portals
    portal={portal_merchandise}
    link="https://checkpointmerchandise.com"
  />,
];


export default function Header() {

  // const [isSafari, setIsSafari] = useState(false);

  // useEffect(() => {
  //   const checkSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  //   setIsSafari(checkSafari);
  // }, []);

  const responsive = {
    0: {
      items: 1,
    },
    400: {
      items: 3,
    },
    900: {
      items: 6,
    },
  };

  return (
    <div className="header-container">
      
      {/* <img className="checkpoint-logo" src={logo} alt="logo" /> */}
      <video className="checkpoint-logo" autoPlay loop muted>
          <source src={logo2} type="video/webm" />
          <source src={logo3} type="video/mp4" />
          Your browser does not support the video tag.
      </video>
      <p className="statement">
        Leader in the impact of videogames on society, culture, education, and
        politics
      </p>
      <div className="portals">
        <AliceCarousel
          mouseTracking
          responsive={responsive}
          items={items}
          infinite
          autoPlay
          disableButtonsControls
          animationDuration={5000}
        />
      </div>
    </div>
  );
}
