import React, {useEffect} from "react";
import "./App.css";
import Home from "./pages/Home";
import { BrowserRouter, Route, Routes, Outlet, useNavigate, useLocation } from "react-router-dom";
import ReactGA from 'react-ga4';

import KidsMain from "./pages/Kids/KidsMain";
import HomeLearning from "./components/Kids/HomeLearning";
import TeacherLessons from "./components/Kids/TeacherLessons";
import Create from "./components/Kids/Create";
import KidsmainMagazine from "./components/Kids/KidsmainMagazine";
import KidsmainBedtimesStories from "./components/Kids/KidsmainBedtimeStories";
import Submissions from "./components/Kids/Submissions";
import KidsmainSlide from "./components/Kids/KidsmainSlide";
import TLessonPreview from "./components/Kids/TLessonPreview";
import HLPreview from "./components/Kids/HLPreview";
import Friends from "./components/Kids/Friends";
import SubmissionsTest from "./components/Kids/SubmissionsTest";
import KidsRedirect from "./components/Kids/KidsRedirect";

const trackingId = 'G-RC4L7JQL05';
ReactGA.initialize(trackingId);


function KidsMainRedirect() {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/kidsmain/kidsmainslide");
  }, [navigate]);

  return null;
}

function App() {
 
  ReactGA.send(window.location.pathname);
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<></>} />
          <Route path="/register" element={<></>} />
          <Route path="/kidsmain/" element={<KidsMain />} >
          <Route index element={<KidsRedirect />} />
            <Route path="/kidsmain/kidsmainslide" element={<Outlet />}>
              <Route
                path="/kidsmain/kidsmainslide"
                element={<KidsmainSlide />}
              />
            </Route>
            <Route path="/kidsmain/magazine" element={<Outlet />}>
              <Route path="/kidsmain/magazine" element={<KidsmainMagazine />} />
            </Route>
            <Route path="/kidsmain/homelearning" element={<Outlet />}>
              <Route path="/kidsmain/homelearning" element={<HomeLearning />} />
            </Route>
            <Route path="/kidsmain/homelearning/:lessonId" element={<Outlet />}>
              <Route
                path="/kidsmain/homelearning/:lessonId"
                element={<HLPreview />}
              />
            </Route>
            <Route path="/kidsmain/teacherlessons" element={<Outlet />}>
              <Route
                path="/kidsmain/teacherlessons"
                element={<TeacherLessons />}
              />
            </Route>
            <Route
              path="/kidsmain/teacherlessons/:lessonId"
              element={<Outlet />}
            >
              <Route
                path="/kidsmain/teacherlessons/:lessonId"
                element={<TLessonPreview />}
              />
            </Route>
            <Route path="/kidsmain/bedtimestories" element={<Outlet />}>
              <Route
                path="/kidsmain/bedtimestories"
                element={<KidsmainBedtimesStories />}
              />
            </Route>
            <Route path="/kidsmain/create" element={<Outlet />}>
              <Route path="/kidsmain/create" element={<Create />} />
            </Route>
            <Route path="/kidsmain/submissions" element={<Outlet />}>
              <Route path="/kidsmain/submissions" element={<Submissions />} />
            </Route>
           {/* <Route path="/kidsmain/submissionstest" element={<Outlet />}>
            <Route path="/kidsmain/submissionstest" element={<SubmissionsTest />}/>
           </Route> */}
            <Route path="/kidsmain/friends" element={<Outlet />}>
              <Route path="/kidsmain/friends" element={<Friends />}/>
            </Route>
            
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
