import React, {useRef} from "react";
import "../../css/Kids/covers.css";

export default function Covers({ webm, mp4, issue, number }) {

  const VideoPlayer = ({ mp4 }) => {
    const videoRef = useRef(null);
  
    const handlePlayClick = () => {
      if (videoRef.current) {
        videoRef.current.play();
      }
    }
  }
  return (
    <div className="covers-cnt">
      <div className="covers-cv">
        <video className="covers-cv-video" height="320px" width="190px" autoPlay loop muted>
          <source src={webm} type="video/webm" />
          <source src={mp4} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <div className="covers-btn-cnt">
        <button className="covers-btn">
          <p>Issue: {issue}</p>
          <p>{number}</p>
        </button>
      </div>
    </div>
  );
}
