import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

import "../../css/Kids/modalpreview.css";
import { AnalyticsEvent } from "../Analytics";

export default function ModalPreview({ image}) {
  const modalRef = useRef(null);
  const [modal, setModal] = useState(false);
  const overlayClickRef = useRef(null); 
  const [selectedImg, setSelectedImg] = useState(null);

  const handleClose = () => {
    setModal(false);
    
  };

  useEffect(()=>{
    const centerModal = () => {
      const modalWindow = modalRef.current;
      if(modalWindow){
        const { clientWidth, clientHeight } = modalWindow;
        modalWindow.style.left = `${(window.innerWidth - clientWidth)/2}px`;
        modalWindow.style.top = `${(window.innerHeight - clientHeight)/2}px`;
      }
    };

    const handleOverlayClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        handleClose();
        event.stopPropagation();
      };
    }

    centerModal();

    window.addEventListener("resize", centerModal);
    window.addEventListener("click", handleOverlayClick);

    return ()=>{
      window.removeEventListener("resize", centerModal);
      window.removeEventListener("click", handleOverlayClick);
    };
  }, []);

  
  


  const handleDownload = async (file) => {
    try {
      //Make a request to the json and convert it to a blob
      const response = await fetch(file.path);
      const blob = await response.blob();
      //Create a url for the blob
      const url = URL.createObjectURL(blob);
      //Create anchor tag and set blob url
      const link = document.createElement("a");
      link.href = url;
      link.download = (file.code)+ "_"+(file.title);
      //click event initiates the download process
      link.click();
      AnalyticsEvent("Opened Create Image", "Downloaded Create PDF", file.code+"_"+file.title);
    } catch (error) {
      AnalyticsEvent("Opened Create Image", "Downloaded Create PDF", file.code+"_"+file.title);
      console.error("Error downloading file: ", error);
    }
  };

  return (
    <div className="mp-cnt"  ref={modalRef}
   
    >
      <div className="mp-content">
        <div className="mp-btn">
          <button className="mp-btn-xmark" onClick={()=>handleClose}>
            <FontAwesomeIcon
              icon={faXmark}
              size="xl"
              className="mp-btn-xmark-icon"
            />
          </button>
        </div>
        <div className="mp-img-cnt">
        <img src={image.preview} alt={image.title} />
        </div>
        <div className="mp-btn-download-cnt">
        <button
          className="mp-btn-download"
          onClick={() => handleDownload(image)}
        >
          Download
        </button>
        </div>
      </div>
    </div>
  );
}


