import React from "react";
import ReactGA from "react-ga4";

export const AnalyticsEvent = (category, action, label) => {

    ReactGA.event({
        category: category,
        action: action,
        label: label
    });

}