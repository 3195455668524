import React, { useEffect, useState } from "react";
import "../../css/Kids/kidsnavbar.css";
import logo from "../../images/checkpoint_logos/Checkpoint®_stacked_72ppi.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDown,
  faBars,
  faCaretDown,
  faCaretUp,
  faSearch,
  faUser,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

function KidsNavbar() {
  const [showDropdown, setShowDropdown] = useState(false);
  const [dropdownArrow, setDropdownArrow] = useState(faCaretDown);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth); 
  const [mobileIcon, setMobileIcon] = useState(faBars);
  const navigate = useNavigate();

  const toggleDropdownMenu = () => {
    setShowDropdown(!showDropdown);
  };

  const handleDropdownArrowClick = () => {
    setDropdownArrow(dropdownArrow === faCaretDown ? faCaretUp : faCaretDown);
  };

  const toggleMobileMenu = () => {
    setShowMobileMenu(!showMobileMenu);
    if (!showMobileMenu) {
      setDropdownArrow(faCaretDown);
      setShowDropdown(false);
    }
  };

  const handleIconClick = () => {
    setMobileIcon(mobileIcon === faBars ? faXmark : faBars);
  };

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  }

  useEffect(()=>{
    //track the width on resize
    window.addEventListener("resize", handleResize);

    return()=>{
      window.removeEventListener("resize", handleResize);
    }
  }, [])

  return (
    <div className="kids-navbar-cnt">
      <div className="home-logo">
        <a href="/">
          <img src={logo} alt="Checkpoint logo" />
        </a>
      </div>
      {windowWidth <= 768 ? (
        <div>
      <div className="kids-icon-menu" onClick={toggleMobileMenu}>
        <FontAwesomeIcon
          className={`kids-dropdown-icon ${showMobileMenu ? "open" : ""}`}
          icon={mobileIcon}
          onClick={handleIconClick}
        />
      </div>
      
      
      {showMobileMenu &&(
      <div className="kids-nav-menu">
        
        <button className="kids-nav-btn">
          <a href="/kidsmain/kidsmainslide">Kids Home</a>
        </button>
        <button className="kids-nav-btn">
          <a href="/kidsmain/magazine">Kids Magazine</a>
        </button>
        <div
          className={`kids-nvb-dpd ${showDropdown ? "show" : ""}`}
          onClick={toggleDropdownMenu}
        >
          <button className="kids-nvb-item" onClick={handleDropdownArrowClick}>
            Learning
            <FontAwesomeIcon className="dropdown-arrow" icon={dropdownArrow} />
          </button>
          <div className="kids-dpd-cnt">
            {showDropdown && (
              <div>
                <button>
                  <a href="/kidsmain/homelearning">Home Learning</a>
                </button>
                <button>
                  <a href="/kidsmain/teacherlessons">Teacher Lessons</a>
                </button>
              </div>
            )}
          </div>
        </div>

        <button className="kids-nav-btn">
          <a href="/kidsmain/bedtimestories">Bedtime stories</a>
        </button>
        <button className="kids-nav-btn">
          <a href="/kidsmain/create">Create</a>
        </button>
        <button className="kids-nav-btn">
          <a href="/kidsmain/submissions">Submissions</a>
        </button>
        <button className="kids-nav-btn">
          <a href="/kidsmain/friends">Friends</a>
        </button>
         
      </div>
     )}
      </div>
       ) : (
        <div className="kids-nav-menu">
        
        <button className="kids-nav-btn">
          <a href="/kidsmain/kidsmainslide">Kids Home</a>
        </button>
        <button className="kids-nav-btn">
          <a href="/kidsmain/magazine">Kids Magazine</a>
        </button>
        <div
          className={`kids-nvb-dpd ${showDropdown ? "show" : ""}`}
          onClick={toggleDropdownMenu}
        >
          <button className="kids-nvb-item" onClick={handleDropdownArrowClick}>
            Learning
            <FontAwesomeIcon className="dropdown-arrow" icon={dropdownArrow} />
          </button>
          <div className="kids-dpd-cnt">
            {showDropdown && (
              <div>
                <button>
                  <a href="/kidsmain/homelearning">Home Learning</a>
                </button>
                <button>
                  <a href="/kidsmain/teacherlessons">Teacher Lessons</a>
                </button>
              </div>
            )}
          </div>
        </div>

        <button className="kids-nav-btn">
          <a href="/kidsmain/bedtimestories">Bedtime stories</a>
        </button>
        <button className="kids-nav-btn">
          <a href="/kidsmain/create">Create</a>
        </button>
        <button className="kids-nav-btn">
          <a href="/kidsmain/submissions">Submissions</a>
        </button>
        <button className="kids-nav-btn">
          <a href="/kidsmain/friends">Friends</a>
        </button>
         
      </div>
       )} 

    </div>
  );
}

export default KidsNavbar;
